<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="岗位名称" :required="true">
            <el-input v-model="form.positionName" placeholder="请输入岗位名称" />
          </el-form-item>
          <el-form-item v-if="refresh" label="公司名称" :required="$admin">
            <el-select
              v-model="form.companyIds"
              class="w-100"
              clearable
              filterable
              :multiple="$admin&&form.isDefault==0"
            >
              <el-option v-for="(v,i) in company" :key="v.id" :value="v.id" :label="v.companyName" />
            </el-select>
          </el-form-item>
          <el-form-item v-show="$admin" label="系统默认" :required="true">
            <el-select v-model="form.isDefault" class="w-100" @change="changeType">
              <el-option :value="0" label="是" />
              <el-option :value="1" label="否" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { company, position } from '@/api'
export default {
  name: 'Operate',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      form: {
        positionName: '',
        companyIds: this.$admin ? [] : [this.$companyId],
        isDefault: 1
      },
      company: [],
      name: sessionStorage.UserName,
      refresh: true
    }
  },
  created() {
    this.getCompany()
  },
  mounted() {
    this.upd()
  },
  methods: {
    upd() {
      if (this.info.addOr === 'upd') {
        this.$axiosReq(position + this.row.id, null, null, 'get').then(res => {
          this.form.isDefault = res.data.isDefault
          this.form.positionName = res.data.positionName
          this.form.companyIds = []
          if (this.form.isDefault == 0) {
            for (const i of res.data.companyNameVoList) {
              this.form.companyIds.push(i.companyId)
            }
          } else {
            for (const i of res.data.companyNameVoList) {
              this.form.companyIds = i.companyId
            }
          }
        })
      }
    },
    getCompany() {
      this.$axiosReq(company, null, {
        pageSize: 10000
      }, 'get').then(res => {
        this.company = res.data.content
				if (this.info.addOr === 'add') {
					this.form.companyIds = res.data.content[0].id
				}
      })
    },
    changeType(v) {
      this.refresh = false
      setTimeout(() => { this.refresh = true }, 10)
      this.form.companyIds = []
    },
    // 提交添加
    commit() {
      if (this.$textNull(this.form.positionName)) {
        this.$message.error('请填写岗位名称！')
        return
      } else if (this.$admin) {
        if (this.$textNull(this.form.companyIds) && this.form.isDefault == 1) {
          this.$message.error('请选择公司！')
          return
        } else if (this.form.companyIds.length == 0 && this.form.isDefault == 0) {
          this.$message.error('请选择公司！')
          return
        }
      }
      let a = {}
      let arr = []
      if (this.$admin) {
        if (this.form.isDefault == 0) {
          arr = this.form.companyIds
        } else {
          arr = [this.form.companyIds]
        }
      } else {
        if (this.$textNull(this.form.companyIds)) {
          arr = [this.$companyId]
        } else {
          arr = [this.form.companyIds]
        }
      }
      a = {
        positionName: this.form.positionName,
        companyIds: arr,
        isDefault: this.form.isDefault
      }
      if (this.info.addOr === 'add') {
        this.$axiosReq(position, a, null, 'post').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        })
      } else {
        this.$axiosReq(position + this.row.id, a, null, 'put').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        })
      }
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
