<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList()"
		 @refresh="refreshDialog" />
		<el-card v-if="$admin">
			<el-row type="flex" justify="space-between" :gutter="50">
				<el-col>
					<span class="text-primary text-pad-right">公司</span>
					<el-select v-model="condition.companyId" filterable clearable size="mini" class="input searchInput" @change="changeCondition">
						<el-option v-for="(v,i) in company" :key="v.id" :value="v.id" :label="v.companyName" />
					</el-select>
				</el-col>
				<!--        <el-col>-->
				<!--          <el-input v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search" placeholder="搜索" @input="changeCondition" />-->
				<!--        </el-col>-->
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button v-if="$hasBtn('sys:permissions:org:jobs:add')" type="primary" size="mini" @click="openDialog({title:'添加岗位',addOr:'add'})">添加岗位</el-button>
								<!--                <el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>-->
							</el-col>
							<el-col :span="1">
								<vxe-button @click="getList()">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="600" row-id="job_id" :sort-config="{remote: true}" :filter-config="{remote: true}"
				 :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column title="公司名称" style="position: relative;" @mouseleave="changeArr">
						<template v-slot="{row}">
							<div class="popHide" @mouseover="mouseoverNow(row.id)">
								<span v-for="(v,i) in row.companyNameVoList" :key="i">
									{{row.companyNameVoList.length==1?v.companyName:v.companyName+','}}
								</span>
							</div>
							<div @mouseleave="changeArr" v-if="row.id == testShowNum && row.companyNameVoList.length > 0" class="popShow">
								<span v-for="(v,i) in row.companyNameVoList" :key="i">
									{{row.companyNameVoList.length==1?v.companyName:v.companyName+','}}
								</span>
							</div>
						</template>
					</vxe-table-column>
					<vxe-table-column field="positionName" title="岗位名称" />
					<vxe-table-column title="岗位类型">
						<template v-slot="{ row, rowIndex }">
							<span v-if="row.isDefault==0">系统岗位</span>
							<span v-else>普通岗位</span>
						</template>
					</vxe-table-column>
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="$hasBtn('sys:permissions:org:jobs:edit')" icon="el-icon-edit" @click.native="openDialog({title:'修改岗位',addOr:'upd'},row)">修改</el-dropdown-item>
									<el-dropdown-item v-if="$hasBtn('sys:permissions:org:jobs:delete')" icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="table.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import Operate from './Operate'
	import {
		company,
		position
	} from '@/api'
	export default {
		name: 'Index',
		components: {
			Operate
		},
		data() {
			return {
				loading: false,
				condition: {
					companyId: this.$admin ? '' : this.$companyId,
					search: ''
				},
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					show: false
				},
				company: [],
				testShowNum: null
			}
		},
		created() {
			this.getCompanyByRegion()
		},
		methods: {
			rowStyle,
			headerStyle,
			getCompanyByRegion() {
				this.$axiosReq(company, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.company = res.data.content
					this.condition.companyId = res.data.content[0].id;
					this.getList();
					this.loading = false;
				}).catch(e => {
					this.loading = false;
				})
			},
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			changeCondition() {
				this.getList()
			},
			getList() {
				this.loading = true
				this.$axiosReq(position, null, {
					companyId: this.condition.companyId,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			remove(info, row) {
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq(position + '/' + row.id, null, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList(this.condition.company)
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			refreshDialog() {
				this.dialog.refresh = false
			},
			changeArr() {
				let that = this;
				that.testShowNum = null;
			},
			mouseoverNow(num) {
				this.testShowNum = num;
			},
		}
	}
</script>

<style scoped>
	.popShow {
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 99;
		background-color: #FFFFFF;
		height: 100%;
		/* text-align: left; */
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		padding: 5px;
		border: 1px solid #6b6b6b;
		border-radius: 3px;
	}
	
	.popShow::-webkit-scrollbar {
		display: none;
	}

	.popHide {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 100%;
		height: 100%;
	}
</style>
